import { transparentize } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { FACTIONS, DISCORDANT_FACTIONS } from '../constants/factions';
import { breakpoints, colors, fonts } from '../constants/variables';
import ColorButton from './ColorButton';
import { usePlayerStore } from '../stores/playerStore';
import { useGameStore } from '../stores/gameStore';
import { useState } from 'react';
import { useEffect } from 'react';
import _sortBy from 'lodash/sortBy';

const Player = styled.div`
  display: flex;
  align-items: center;
`;

const FactionIcon = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  width: 65px;

  @media only screen and (max-width: ${breakpoints.sm}) {
    width: 3rem;
  }

  @media only screen and (max-width: ${breakpoints.xs}) {
    display: none;
  }
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const FactionSelect = styled.select`
  appearance: none;
  background-color: transparent;
  border: solid 1px ${transparentize(0.75, colors.actionColor)};
  font-family: ${fonts.fancy};
  font-weight: 300;
  width: 100%;
  padding: 0.25rem 0.5rem 0;
  min-height: 36px;
  margin: 0;
  font-size: 1.25rem;
  outline: none;
  color: ${colors.white};
  transition: border 0.3s, background-color 0.3s;
  background-image: url('/expand_more.svg');
  background-repeat: no-repeat;
  background-position: right center;
  border-radius: 0;

  &:hover {
    background-color: rgba(0, 0, 0, 0.25);
    cursor: pointer;
  }

  &:focus {
    outline: none;
    background-color: rgba(0, 0, 0, 0.5);
    border: solid 1px ${colors.actionColor};
  }

  @media only screen and (max-width: ${breakpoints.xs}) {
    padding-left: 0;
  }
`;

const PlayerName = styled.input`
  border: solid 1px transparent;
  line-height: 1.5;
  background-color: transparent;
  color: ${colors.white};
  font-size: 1rem;
  transition: all 0.3s;
  padding: 0 0.5rem 0.25rem;
  margin: 0;

  &:hover {
    background-color: rgba(0, 0, 0, 0.25);
    cursor: text;
  }

  &:focus {
    outline: none;
    border: solid 1px ${colors.actionColor};
    background-color: rgba(0, 0, 0, 0.5);
  }

  @media only screen and (max-width: ${breakpoints.xs}) {
    padding-left: 0;
  }
`;

const Img = styled.img`
  max-height: 50px;
  height: ${(props) => (props.icon ? 'auto' : '1.5rem')};

  @media only screen and (max-width: ${breakpoints.sm}) {
    max-width: 2rem;
  }
`;

const PlayerRow = ({ id }) => {
  const [factionsList, setList] = useState(_sortBy(FACTIONS, 'value'));
  const [chosenFactions, players, setName, setFaction] = usePlayerStore(
    (state) => [
      state.chosenFactions,
      state.players,
      state.setName,
      state.setFaction,
    ]
  );

  const [showDiscordant] = useGameStore((state) => [state.showDiscordant]);

  const faction = players[id].faction;
  const name = players[id].name;

  // update the factions list depending on the checkbox of Discordant
  useEffect(() => {
    if (showDiscordant) {
      setList(_sortBy([...FACTIONS, ...DISCORDANT_FACTIONS], 'value'));
    } else {
      setList(_sortBy(FACTIONS, 'value'));
    }
  }, [showDiscordant]);

  let dropdownValue = 'choose';
  if ((faction && faction.id) || (faction && faction.id === 0))
    dropdownValue = faction.id;
  return (
    <Player>
      <FactionIcon>
        <Img
          icon={faction.icon}
          src={faction && faction.icon ? faction.icon : '/question_mark.svg'}
          alt=""
        />
      </FactionIcon>
      <Inputs>
        <FactionSelect
          aria-label="Choose a faction"
          value={dropdownValue}
          onChange={(e) => setFaction(id, e.target.value)}
        >
          <option value={'choose'}>Choose a Faction</option>
          {factionsList.map((faction) => {
            return (
              <option
                disabled={chosenFactions[faction.id] === true}
                key={faction.id}
                value={faction.id}
              >
                {faction.name}
              </option>
            );
          })}
        </FactionSelect>
        <PlayerName
          type="text"
          placeholder="Player Name (optional)"
          value={name}
          onChange={(e) => setName(id, e.target.value)}
        />
      </Inputs>
      <ColorButton id={id} />
    </Player>
  );
};

export default PlayerRow;
