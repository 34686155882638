import { useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { fonts } from '../constants/variables';

const Box = styled.div`
  display: flex;
  flex-direction: column;
`;

const Heading = styled.span`
  display: block;
  font-size: 1.25rem;
  font-family: ${fonts.mono};
`;

const Unscored = styled.span`
  opacity: 0.25;
`;

const BonusBlock = ({ label, player }) => {
  const [scored, setScored] = useState([]);

  useEffect(() => {
    setScored([]);
    if (player.point_by_type.custodians) {
      setScored((scored) => [...scored, "Claimed Custodian's Token"]);
    }
    if (player.point_by_type.mutiny > 0) {
      setScored((scored) => [...scored, 'Won a Mutiny']);
    } else if (player.point_by_type.mutiny < 0) {
      setScored((scored) => [...scored, 'Lost a Mutiny']);
    }
    if (player.point_by_type.seed) {
      setScored((scored) => [...scored, 'Seeded an Empire']);
    }
    if (player.point_by_type.censure) {
      setScored((scored) => [...scored, 'Politically Censured']);
    }
    if (player.point_by_type.crown) {
      setScored((scored) => [...scored, 'Purged Crown of Emphidia']);
    }
    if (player.point_by_type.shard) {
      setScored((scored) => [...scored, 'Has Shard of the Throne']);
    }
    if (player.point_by_type.rider) {
      setScored((scored) => [...scored, 'Played Imperial Rider']);
    }
  }, [player.point_by_type]);
  return (
    <Box>
      <Heading>{label}</Heading>
      {scored.length > 0 ? (
        scored.map((obj) => {
          return (
            <span key={obj}>
              {obj}
              {obj === 'Played Imperial Rider' && player.point_by_type.rider > 1
                ? ` (x${player.point_by_type.rider})`
                : null}
            </span>
          );
        })
      ) : (
        <Unscored>
          <em>None</em>
        </Unscored>
      )}
    </Box>
  );
};

export default BonusBlock;
