import React, { useState } from 'react';
import styled from 'styled-components';
import { transparentize } from 'polished';
import { breakpoints, colors } from '../constants/variables';
import { useGameStore } from '../stores/gameStore';
import { usePlayerStore } from '../stores/playerStore';
import Tabs from '../components/Tabs';
import GameNav from '../components/GameNav';
import Agendas from '../components/Agendas';
import Relics from '../components/Relics';
import ActionCards from '../components/ActionCards';
import Button from '../components/Button';
import Chart from '../components/Chart';
import FactionScorer from '../components/FactionScorer';
import ObjectiveContainer from '../components/Objectives/ObjectiveContainer';
import TextButton from '../components/TextButton';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { titleText } from '../utils/helpers';
import _ from 'lodash';
import FlipMove from 'react-flip-move';
import Agenda from '../components/Agenda/Agenda';

const Hero = styled.h1`
  font-size: 2.25rem;
  margin: 0;
  text-align: center;
  display: flex;
  justify-content: center;

  @media only screen and (max-height: 50em) {
    font-size: 1.75rem;
  }

  @media only screen and (max-width: ${breakpoints.sm}) {
    font-size: 1.5rem;
  }
`;

const H1 = styled.h1`
  margin: 0;
  border-right: solid 1px ${transparentize(0.5, colors.white)};
  padding-right: 0.5rem;
`;

const Span = styled.span`
  &:first-of-type {
    padding-right: 1rem;
  }

  &:last-of-type {
    border-left: solid 2px ${transparentize(0.5, colors.white)};
    padding-left: 1rem;
  }
`;

const GameBox = styled.div`
  display: grid;
  grid-gap: 2rem;
  height: ${(props) =>
    props.gameStarted ? 'calc(100vh - 9.75em)' : 'calc(100vh - 14.25em)'};
  max-height: 100%;

  @media only screen and (min-width: ${breakpoints.xxl}) {
    grid-template-columns: ${(props) =>
      props.view === 'agenda' ? '1fr' : '5fr 2fr'};
  }

  @media only screen and (min-width: ${breakpoints.xl}) and (max-width: ${breakpoints.xxl}) {
    grid-template-columns: ${(props) =>
      props.view === 'agenda' ? '1fr' : '5fr 2fr'};
  }

  @media only screen and (min-width: ${breakpoints.lg}) and (max-width: ${breakpoints.xl}) {
    grid-template-columns: ${(props) =>
      props.view === 'agenda' ? '1fr' : '2fr 1.1fr'};
  }

  @media only screen and (min-width: ${breakpoints.md}) and (max-width: ${breakpoints.lg}) {
    grid-template-columns: ${(props) =>
      props.view === 'agenda' ? '1fr' : '3fr 2fr'};
  }

  @media only screen and (min-width: ${breakpoints.sm}) and (max-width: ${breakpoints.md}) {
    grid-template-columns: ${(props) =>
      props.view === 'agenda' ? '1fr' : 'repeat(2, 1fr)'};
    height: ${(props) => (props.view === 'agenda' ? 'auto' : null)};
  }

  @media only screen and (max-width: ${breakpoints.sm}) {
    grid-template-columns: 1fr;
    height: auto;
  }
`;

const FactionList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-height: 100%;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const RightColumn = styled.section`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 1rem;

  @media only screen and (min-width: ${breakpoints.xs}) {
    > button {
      width: min-content;
    }
  }
`;

const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Flex = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const Play = () => {
  // set the title for the page
  titleText('Game in Progress - ti4score');

  // needed for redirecting back to home if game has not started
  let navigate = useNavigate();
  const [
    currentRound,
    nextRound,
    view,
    setView,
    variants,
    expanded,
    setExpand,
    gameStarted,
    nextObjective,
    custodiansClaimed,
    agendaPhase,
  ] = useGameStore((state) => [
    state.currentRound,
    state.nextRound,
    state.view,
    state.setView,
    state.variants,
    state.expanded,
    state.setExpand,
    state.gameStarted,
    state.nextObjective,
    state.custodiansClaimed,
    state.agendaPhase,
  ]);
  const [
    players,
    playersNextRound,
    playerCount,
    winner,
    scoreLimit,
    expectedRounds,
    setExpectedRounds,
  ] = usePlayerStore((state) => [
    state.players,
    state.playersNextRound,
    state.playerCount,
    state.winner,
    state.scoreLimit,
    state.expectedRounds,
    state.setExpectedRounds,
  ]);
  const [focused, setFocused] = useState(undefined);

  // toggle for switching functionality of the Next Round button
  const [showRound, setShow] = useState(variants.redTape ? true : false);
  const [showAgenda, setAgendaShow] = useState(false);

  const focusFaction = (id) => {
    if (focused === id) {
      setFocused(undefined);
    } else {
      setFocused(id);
      setView('chart');
    }
  };

  const checkRound = () => {
    if (currentRound >= expectedRounds.length) {
      setExpectedRounds(currentRound + 1);
    }
  };

  const gameLength = variants.fourFourFour ? 7 : 9;

  const hasWinner = Object.keys(winner).length !== 0;

  // if no game has started, return to the home page
  useEffect(() => {
    if (!gameStarted) {
      navigate('/', { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // get a list of players, sorted by highest score
  const sortedPlayers = _.orderBy(players, 'totalPoints', 'desc');

  return (
    <>
      <FlexBetween>
        <Tabs />
        <GameNav />
      </FlexBetween>
      <Hero>
        <Span>Round: {currentRound}</Span>
        <Span>{scoreLimit} Point Game</Span>
      </Hero>
      <FlexBetween>
        <Flex>
          <Agendas />
          <Relics />
          <ActionCards />
        </Flex>
        {(hasWinner || currentRound === gameLength) && (
          <Button size="xs" outline disabled={true}>
            {!hasWinner && 'Last Round'}
            {hasWinner && 'Game Has Ended'}
          </Button>
        )}
        {!showRound &&
          currentRound !== gameLength &&
          !hasWinner &&
          !showAgenda && (
            <Button
              size="xs"
              outline
              disabled={currentRound === gameLength || hasWinner}
              onClick={() => {
                nextObjective();
                setShow(!showRound);
                if (custodiansClaimed) {
                  setAgendaShow(true);
                }
              }}
            >
              {variants.redTape ? 'Unlock' : 'Reveal'} Objective
            </Button>
          )}
        {!hasWinner && showAgenda && (
          <Button
            size="xs"
            outline
            disabled={hasWinner}
            onClick={() => {
              agendaPhase();
              setAgendaShow(false);
            }}
          >
            Agenda Phase
          </Button>
        )}
        {showRound &&
          currentRound !== gameLength &&
          !hasWinner &&
          !showAgenda && (
            <Button
              size="xs"
              outline
              disabled={currentRound === gameLength || hasWinner}
              onClick={() => {
                !variants.redTape ? setShow(!showRound) : nextObjective();
                nextRound();
                playersNextRound(currentRound);
                checkRound();
              }}
            >
              Begin Round {currentRound + 1}
            </Button>
          )}
      </FlexBetween>
      <GameBox view={view} gameStarted={gameStarted}>
        {view === 'chart' && <Chart focused={focused} />}
        {view === 'objectives' && <ObjectiveContainer />}
        {view === 'agenda' && <Agenda />}
        {view !== 'agenda' && (
          <RightColumn aria-labelledby="players">
            <Flex>
              <H1 id="players">Players</H1>
              <TextButton
                style={{ padding: '0' }}
                lg
                onClick={() => setExpand(!expanded)}
              >
                {expanded ? 'Collapse All' : 'Expand All'}
              </TextButton>
            </Flex>
            <FactionList count={playerCount}>
              <FlipMove>
                {sortedPlayers.map((player) => {
                  const isFocused =
                    focused === undefined
                      ? true
                      : player.faction.id === focused
                      ? true
                      : false;
                  return (
                    <FactionScorer
                      key={player.faction.id}
                      player={player}
                      focused={isFocused}
                      focusFaction={() => focusFaction(player.faction.id)}
                    />
                  );
                })}
              </FlipMove>
            </FactionList>
          </RightColumn>
        )}
      </GameBox>
    </>
  );
};

export default Play;
