export const initialState = {
  deleteSaveEvent: false,
  showModal: false,
  background: 'https://ik.imagekit.io/ti4score/tr:bl-5/ti_background_alt.webp',
  modalKey: undefined,
  modalType: undefined,
  alert: { type: undefined, text: '', show: false },
  availStage1: 2,
  availStage2: 0,
  maxStage1: 5,
  maxStage2: 5,
  maxSecret: 3,
  maxClassified: 1,
  gameStarted: false,
  saveKey: null,
  currentRound: 1,
  showScoring: false,
  view: 'objectives',
  classifiedDocument: false,
  classifiedRepealed: false,
  obsidian: false,
  custodiansClaimed: false,
  crownPurged: false,
  shardOfTheThrone: false,
  miscountDisclosed: false,
  mutiny: false,
  seedOfEmpire: false,
  politicalCensure: false,
  censureRepealed: false,
  imperialRider: false,
  incentiveProgram: false,
  incentiveType: undefined,
  progression10: [
    { x: 1, y: 1 },
    { x: 2, y: 2 },
    { x: 3, y: 6 },
    { x: 4, y: 8 },
    { x: 5, y: 10 },
    { x: 6, y: 10 },
    { x: 7, y: 10 },
    { x: 8, y: 10 },
    { x: 9, y: 10 },
  ],
  progression12: [
    { x: 1, y: 1 },
    { x: 2, y: 2 },
    { x: 3, y: 6 },
    { x: 4, y: 8 },
    { x: 5, y: 10 },
    { x: 6, y: 12 },
    { x: 7, y: 12 },
  ],
  progression14: [
    { x: 1, y: 1 },
    { x: 2, y: 2 },
    { x: 3, y: 6 },
    { x: 4, y: 8 },
    { x: 5, y: 10 },
    { x: 6, y: 12 },
    { x: 7, y: 14 },
    { x: 8, y: 14 },
    { x: 9, y: 14 },
  ],
  selectedAgenda: undefined,
  laws: [],
  votes: {
    for: 0,
    against: 0,
    elect: [],
  },
  objectives: [
    {
      id: 0,
      name: 'stage_1',
      list: [undefined, undefined, undefined, undefined, undefined],
    },
    {
      id: 1,
      name: 'stage_2',
      list: [undefined, undefined, undefined, undefined, undefined],
    },
    {
      id: 2,
      name: 'classified',
      list: [undefined],
    },
  ],
  expanded: false,
  misc: false,
  variants: {
    redTape: false,
    fourFourFour: false,
  },
  showDiscordant: false,
};
