import { transparentize } from 'polished';
import styled from 'styled-components';
import { colors } from '../constants/variables';
import { useGameStore } from '../stores/gameStore';
import EmptyState from './EmptyState';
import TextButton from './TextButton';

const Ul = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: calc(100vh - 23em);
  max-width: 100%;
  width: 40rem;
  border: solid 1px ${transparentize(0.5, colors.border)};
  border-radius: 0.5rem;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Li = styled.li`
  margin: 0;
  background-color: ${(props) =>
    props.active ? transparentize(0.25, colors.actionColor) : 'transparent'};
  border: none;
  padding: 1rem;
  transition: background-color 0.3s, color 0.3s;
  display: flex;
  justify-content: space-between;

  > button {
    padding: 0;
    color: ${(props) => (props.active ? colors.white : colors.actionColor)};
    opacity: ${(props) => (props.active ? 1 : 0)};
    transition: opacity 0.3s, color 0.3s;

    &:hover:not([disabled]) {
      color: ${colors.white};
    }
  }

  &:hover {
    cursor: pointer;
    background-color: ${(props) =>
      props.active
        ? transparentize(0.25, colors.actionColor)
        : transparentize(0.95, colors.white)};
    > button {
      opacity: 1;
    }
  }

  &:not(:last-of-type) {
    border-bottom: solid 1px ${transparentize(0.5, colors.border)};
  }
`;

const List = ({ list, selected, onClick }) => {
  const [toggleModal, setModalKey] = useGameStore((state) => [
    state.toggleModal,
    state.setModalKey,
  ]);
  return (
    <Ul>
      {list ? (
        list.map((item, index) => {
          return (
            <Li
              key={index}
              onClick={() => onClick(item)}
              active={item === selected}
            >
              <span>{item}</span>
              <TextButton
                lg
                onClick={() => {
                  setModalKey(item);
                  toggleModal('delete');
                }}
              >
                Delete
              </TextButton>
            </Li>
          );
        })
      ) : (
        <EmptyState
          title="No games to load"
          text="You can save games while you play, and they will appear here."
        />
      )}
    </Ul>
  );
};

export default List;
