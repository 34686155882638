export const player = {
  name: '',
  faction: {},
  points: [
    { x: 1, y: 0 },
    { x: 2, y: 0 },
    { x: 3, y: 0 },
    { x: 4, y: 0 },
    { x: 5, y: 0 },
    { x: 6, y: 0 },
    { x: 7, y: 0 },
    { x: 8, y: 0 },
    { x: 9, y: 0 },
  ],
  totalPoints: 0,
  votes: 0,
  hasObsidian: false,
  point_by_type: {
    stage_1: 0,
    stage_2: 0,
    secret: 0,
    classified: 0,
    custodians: 0,
    imperial: 0,
    crown: 0,
    shard: 0,
    mutiny: 0,
    seed: 0,
    censure: 0,
    rider: 0,
    support: 0,
    misc: 0,
  },
  scored_objectives: {
    stage_1: [],
    stage_2: [],
    classified: [],
  },
  color: '',
};

export const PLAYER_COLORS = [
  'red',
  'blue',
  'green',
  'black',
  'orange',
  'yellow',
  'magenta',
  'purple',
  'white',
];
