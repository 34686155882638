import styled from 'styled-components';
import { colors, fonts } from '../../constants/variables';
import { transparentize } from 'polished';
import { useGameStore } from '../../stores/gameStore';
import Button from '../Button';

const Div = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

const Input = styled.input`
  width: ${(props) => (props.width ? props.width : '2em')};
  font-size: ${(props) => (props.type === 'number' ? '2rem' : '1.25rem')};
  height: 2.5rem;
  line-height: 2.5rem;
  padding: 0 0.25rem;
  font-family: ${(props) =>
    props.type === 'number' ? `${fonts.mono}` : `${fonts.standard}`};
  background-color: ${transparentize(0.5, colors.black)};
  border: solid 1px ${transparentize(0.5, colors.actionColor)};
  border-radius: 0.25rem;
  color: ${colors.white};
  outline: none;
  transition: border 0.3s, box-shadow 0.3s;
  text-align: ${(props) => (props.type === 'number' ? 'center' : 'left')};
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    appearance: textfield;
    -moz-appearance: textfield;
  }

  &:hover {
    border: solid 1px ${colors.actionColor};
  }

  &:focus {
    border: solid 1px ${colors.actionColor};
    box-shadow: 0 0 0 3px ${transparentize(0.5, colors.actionColor)};
  }
`;

const ElectRow = ({ option }) => {
  const [selectedAgenda, handleVoteElect] = useGameStore((state) => [
    state.selectedAgenda,
    state.handleVoteElect,
  ]);
  const electType = selectedAgenda
    ? selectedAgenda.elect
    : 'whatever is being elected';

  const handleFocus = (event) => event.target.select();

  const updateInput = (type, value) => {
    handleVoteElect('elect', option.id, type, value);
  };
  return (
    <Div>
      <Input
        value={option.name}
        width="100%"
        type="text"
        placeholder={electType}
        onChange={(e) => updateInput('name', e.target.value)}
      />
      <Flex>
        <Button
          size="xs"
          outline
          square
          aria-label="Decrease vote amount"
          disabled={option.votes === 0}
          onClick={(e) => updateInput('votes', (option.votes -= 1))}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#FFFFFF"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M19 13H5v-2h14v2z" />
          </svg>
        </Button>
        <Input
          type="number"
          value={option.votes}
          onFocus={handleFocus}
          onChange={(e) => updateInput('votes', e.target.value)}
        />
        <Button
          size="xs"
          outline
          square
          aria-label="Increase vote amount"
          onClick={(e) => updateInput('votes', (option.votes += 1))}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#FFFFFF"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
          </svg>
        </Button>
      </Flex>
    </Div>
  );
};

export default ElectRow;
