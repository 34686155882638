import { darken, transparentize } from 'polished';
import styled, { css } from 'styled-components';
import { breakpoints, colors, fonts } from '../constants/variables';

const StyledButton = styled.button`
  ${(props) => {
    switch (props.$outline) {
      case true:
        return css`
          background-color: ${(props) =>
            props.$active
              ? darken(0.15, colors.actionColor)
              : transparentize(0.5, colors.black)};
          color: ${(props) =>
            props.$active ? colors.white : colors.actionColor};
          border: solid 1px ${darken(0.15, colors.actionColor)};
          &:hover:not([disabled]) {
            cursor: pointer;
            background-color: ${(props) =>
              props.$active
                ? darken(0.1, colors.actionColor)
                : transparentize(0.8, colors.actionColor)};
          }

          svg {
            fill: ${colors.actionColor};
          }
        `;
      default:
        return css`
          background-color: ${darken(0.15, colors.actionColor)};
          border: solid 1px ${darken(0.15, colors.actionColor)};
          color: ${colors.white};
          &:hover:not([disabled]) {
            cursor: pointer;
            background-color: ${darken(0.25, colors.actionColor)};
          }

          svg {
            fill: ${colors.white};
          }
        `;
    }
  }};
  ${(props) => {
    switch (props.size) {
      case 'xs':
        return css`
          padding: ${props.$square ? '0.25rem' : '0.25rem 0.5rem'};
          font-size: 1rem;

          @media only screen and (max-width: ${breakpoints.xs}) {
            font-size: 0.85rem;
          }
        `;
      case 'sm':
        return css`
          padding: ${props.$square ? '0.5rem' : '0.5rem 1rem'};
          font-size: 1.25rem;
        `;
      default:
        return css`
          padding: ${props.$square ? '1.5rem' : '1rem 1.5rem'};
          font-size: 1.5rem;
        `;
    }
  }}
  ${(props) => {
    switch (props.$unrounded) {
      case true:
        return css`
          border-radius: 0;
        `;
      default:
        return css`
          border-radius: 0.25rem;
        `;
    }
  }}
  display: flex;
  font-family: ${fonts.standard};
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  width: auto;
  transition: background-color 0.3s, box-shadow 0.3s;

  &:disabled {
    opacity: 0.5;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px ${transparentize(0.5, colors.actionColor)};
  }
`;

const Button = ({
  className,
  children,
  onClick,
  disabled,
  autoFocus,
  outline,
  active,
  size,
  square,
  unrounded,
  ...props
}) => {
  return (
    <StyledButton
      disabled={disabled}
      className={className}
      onClick={onClick}
      autoFocus={autoFocus}
      $outline={outline}
      $active={active}
      size={size}
      $square={square}
      $unrounded={unrounded}
      {...props}
    >
      {children}
    </StyledButton>
  );
};

Button.defaultProps = {
  outline: false,
  active: false,
  square: false,
  unrounded: false,
};

export default Button;
