import React from 'react';
import { FACTIONS } from '../constants/factions';
import { PLAYER_COLORS } from '../constants/players';

const STORAGE_KEY = 'SAVED_GAMES';

export const useOutsideClick = (callback) => {
  const ref = React.useRef();

  React.useEffect(() => {
    const handleClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [callback, ref]);

  return ref;
};

export const getSaveKey = (playerCount) => {
  const date = new Date();
  return `${playerCount} player game, ${date.toDateString()} @ ${date.toLocaleTimeString()}`;
};

export const saveToLocal = (gameState, saveKey) => {
  let savedGames = localStorage.getItem(STORAGE_KEY);
  if (savedGames) {
    savedGames = JSON.parse(savedGames);
    // use existing saved games entry
    savedGames[saveKey] = encodeSaveData(gameState);
    localStorage.setItem(STORAGE_KEY, JSON.stringify(savedGames));
  } else {
    // create new saved games entry
    const newSavedGames = {};
    newSavedGames[saveKey] = encodeSaveData(gameState);
    localStorage.setItem(STORAGE_KEY, JSON.stringify(newSavedGames));
  }
};

export const getGameStateFromLocal = (saveKey) => {
  let savedGames = localStorage.getItem(STORAGE_KEY);
  if (savedGames) {
    savedGames = JSON.parse(savedGames);
    return decodeSaveData(savedGames[saveKey]);
  }
};

export const getSavedGameKeys = () => {
  let savedGames = localStorage.getItem(STORAGE_KEY);
  if (savedGames) {
    savedGames = JSON.parse(savedGames);
    return Object.keys(savedGames);
  }
};

export const deleteSaveFromLocal = (saveKey) => {
  let savedGames = localStorage.getItem(STORAGE_KEY);
  if (savedGames) {
    savedGames = JSON.parse(savedGames);
    if (savedGames[saveKey]) {
      delete savedGames[saveKey];
      localStorage.setItem(STORAGE_KEY, JSON.stringify(savedGames));
    }
  }
};

const encodeSaveData = (gameState) => {
  // base64 encode save data
  return window.btoa(JSON.stringify(gameState));
};

const decodeSaveData = (gameState) => {
  // decode save data using base64
  return JSON.parse(window.atob(gameState));
};

export const getInitialFactionsHash = () => {
  const chosenFactions = {};
  FACTIONS.forEach((faction) => (chosenFactions[faction.id] = false));
  return chosenFactions;
};

export const getInitialChosenColorsHash = () => {
  const chosenColors = {};
  PLAYER_COLORS.forEach((color) => (chosenColors[color] = false));
  return chosenColors;
};

export const titleText = (newTitle) => {
  return (document.title = newTitle);
};
