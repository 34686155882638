import styled from 'styled-components';
import Panel from './Panel';
import React from 'react';
import { breakpoints, colors } from '../constants/variables';
import { transparentize } from 'polished';
import Alert from './Alert';
import { Outlet } from 'react-router-dom';
import VisitCounterContainer from '../containers/VisitCounterContainer';
import { useGameStore } from '../stores/gameStore';

const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: ${breakpoints.xxl};
  overflow-x: hidden;
  margin: 0 auto;
`;

const Credit = styled.div`
  display: flex;
  margin: 0 auto;
  color: ${transparentize(0.5, colors.white)};
  font-size: 0.75rem;
  text-align: center;
  gap: 1rem;
  align-items: center;
  padding: 0.25rem 0;

  @media only screen and (max-width: ${breakpoints.xs}) {
    flex-direction: column;
    gap: 0.25rem;
  }
`;

const Main = () => {
  const [gameStarted] = useGameStore((state) => [state.gameStarted]);
  return (
    <StyledMain>
      <Alert />
      <Panel>
        <Outlet />
      </Panel>
      {!gameStarted && (
        <Credit>
          <span>
            Vector graphics by&nbsp;
            <a
              target="_blank"
              href="https://boardgamegeek.com/user/Polarstern"
              rel="noreferrer"
            >
              Lars Riecken
            </a>
            . Twilight Imperium&trade; and all its expansions are the Property
            of&nbsp;
            <a
              target="_blank"
              href="https://www.fantasyflightgames.com/en/index/"
              rel="noreferrer"
            >
              Fantasy Flight Games
            </a>
            &reg;
          </span>
          <VisitCounterContainer />
        </Credit>
      )}
    </StyledMain>
  );
};

export default Main;
