import styled from 'styled-components';
import Button from '../Button';

const Div = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-gap: 2rem;
  grid-template-areas:
    'row-1a row-1b'
    'row-2a row-2b'
    'row-3a row-3b'
    'row-4 row-4';
`;

const CovertSelect = ({ handleCovert }) => {
  return (
    <Div>
      <h1>Speaker: Select the Agenda Outcome</h1>
      <Grid>
        <Button
          size="xs"
          onClick={() => handleCovert('for')}
          style={{ gridArea: 'row-1a' }}
        >
          For / Against
        </Button>
        <Button
          size="xs"
          onClick={() => handleCovert('Player')}
          style={{ gridArea: 'row-1b' }}
        >
          Elect Player
        </Button>
        <Button
          size="xs"
          onClick={() => handleCovert('Law')}
          style={{ gridArea: 'row-2a' }}
        >
          Elect Law
        </Button>
        <Button
          size="xs"
          onClick={() => handleCovert('Planet')}
          style={{ gridArea: 'row-2b' }}
        >
          Elect Planet
        </Button>
        <Button
          size="xs"
          onClick={() => handleCovert('Scored Secret Objective')}
          style={{ gridArea: 'row-3a' }}
        >
          Elect Scored Secret
        </Button>
        <Button
          size="xs"
          onClick={() => handleCovert('Strategy Card')}
          style={{ gridArea: 'row-3b' }}
        >
          Elect Strategy Card
        </Button>
        <Button
          size="xs"
          onClick={() => handleCovert('Non-home, non-Mecatol Rex planet')}
          style={{ gridArea: 'row-4' }}
        >
          Elect Non-home, non-Mecatol Rex planet
        </Button>
      </Grid>
    </Div>
  );
};

export default CovertSelect;
