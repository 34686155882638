import styled, { css } from 'styled-components';
import React from 'react';
import { breakpoints, colors, fonts } from '../../constants/variables';
import { mix, transparentize } from 'polished';
import { AGENDAS } from '../../constants/agendas';
import { useState } from 'react';
import { useGameStore } from '../../stores/gameStore';
import _ from 'lodash';

const Block = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  justify-self: center;
  min-width: ${(props) => (props.selected ? null : '19em')};
  min-height: ${(props) => (props.selected ? null : '26em')};
  max-width: 20em;
  align-self: center;

  @media only screen and (max-width: ${breakpoints.xl}) {
    max-width: 20em;
  }

  @media only screen and (max-width: ${breakpoints.lg}) {
    max-width: 18em;
  }

  @media only screen and (max-width: ${breakpoints.md}) {
    min-width: ${(props) => (props.selected ? null : '14em')};
    min-height: ${(props) => (props.selected ? null : '19em')};
    max-width: 16em;
  }

  @media only screen and (max-width: ${breakpoints.xs}) {
    max-width: 15em;
  }
`;

const SelectWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;

  @media only screen and (max-width: ${breakpoints.lg}) {
    width: 100%;
    justify-self: start;
  }
`;

const StyledSelect = styled.select`
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  position: absolute;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  outline: none;
  opacity: 0;
  color: ${colors.white};

  &:focus {
    background-color: rgba(0, 0, 0, 0.5);

    + div {
      box-shadow: 0 0 0 4px ${transparentize(0.5, colors.agenda)};
    }
  }
`;

const Div = styled.div`
  color: ${colors.white};
  flex: 1;
  text-align: center;
  border: solid 2px ${colors.agenda};
  ${(props) => {
    return css`
      background-color: ${(props) =>
        props.selected === ''
          ? mix(0.66, colors.cardBack, colors.agenda)
          : colors.cardBack};
    `;
  }}
  padding: ${(props) => (props.selected ? '1rem 1rem 2.25rem' : '1rem')};
  font-family: ${fonts.standard};
  text-align: center;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  justify-content: ${(props) =>
    props.selected !== '' ? 'space-between' : 'center'};
  align-items: center;
  border-radius: 0.5rem;
  transition: box-shadow 0.3s, opacity 0.3s;
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};

  @media only screen and (max-width: ${breakpoints.lg}) {
    flex-direction: row-reverse;
    text-align: left;
    align-items: ${(props) =>
      props.selected === '' ? 'center' : 'flex-start'};
    position: relative;
    min-height: initial;
    height: auto;
  }

  @media only screen and (max-width: ${breakpoints.md}) {
    padding: ${(props) =>
      props.selected ? '0.5rem 0.5rem 2.25rem' : '0.5rem'};
  }
`;

const Flex = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: ${breakpoints.sm}) {
    height: 100%;
  }
`;

const CardBack = styled.img`
  height: 80px;
  width: 80px;

  @media only screen and (max-width: ${breakpoints.lg}) {
    height: 50px;
    width: 50px;
  }
`;

const Heading = styled.div`
  display: flex;
  flex-direction: column;
`;

const H1 = styled.h1`
  font-family: ${fonts.standard};
  color: mix(0.25, ${colors.agenda}, ${colors.white});
  pointer-events: none;
  font-size: 1.25rem;
  font-weight: 700;
  margin: 0;
  text-align: center;

  @media only screen and (max-width: ${breakpoints.md}) {
    font-size: 1rem;
  }
`;

const H2 = styled.h2`
  text-transform: capitalize;
  font-size: 1rem;
  padding-bottom: 0.5rem;
  color: ${(props) => (props.type === 'law' ? colors.law : colors.directive)};
  text-align: center;
`;

const Text = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  gap: 1.5rem;
  flex: 1;
  z-index: 2;
  background-color: ${colors.white};
  padding: 1rem;
  border-radius: 0.25rem;

  @media only screen and (max-width: ${breakpoints.lg}) {
    justify-content: flex-start;
    gap: 0.75rem;
  }
`;

const P = styled.p`
  font-size: 1.25rem;
  font-weight: 400;
  margin: 0;
  text-align: left;
  color: ${colors.black};

  @media only screen and (max-width: ${breakpoints.lg}) {
    font-size: 1.25rem;
  }

  @media only screen and (max-width: ${breakpoints.md}) {
    font-size: 1rem;
  }

  @media only screen and (max-width: ${breakpoints.sm}) {
    font-size: 1rem;
  }
`;

const POK = styled.img`
  position: absolute;
  right: 0.5rem;
  bottom: 0.5rem;
  width: 15px;
  z-index: 1;
`;

const AgendaCard = () => {
  const [selectedAgenda, selectAgenda] = useGameStore((state) => [
    state.selectedAgenda,
    state.selectAgenda,
  ]);
  const [selected, setSelected] = useState(
    selectedAgenda !== undefined ? selectedAgenda.name : ''
  );
  // get the list of agendas
  const agendas = _.sortBy(AGENDAS, ['name']);

  const findSelectedName = (id) => {
    const selectedAgenda = agendas.find((agenda) => agenda.id === Number(id));
    if (selectedAgenda) {
      const selectedName = selectedAgenda.name;

      return selectedName;
    } else {
      return null;
    }
  };

  const chooseAgenda = (id) => {
    if (id === '') {
      selectAgenda(undefined);
      setSelected('');
      return;
    }
    selectAgenda(agendas.find((agenda) => agenda.id === Number(id)));
    setSelected(findSelectedName(id));
  };
  return (
    <Block selected={selected}>
      <SelectWrapper>
        <StyledSelect onChange={(e) => chooseAgenda(e.target.value)}>
          <option value="">Select an Agenda</option>
          {agendas.map((agenda) => {
            return (
              <option key={agenda.name} value={agenda.id}>
                {agenda.name}
              </option>
            );
          })}
        </StyledSelect>
        {selected && selectedAgenda.pok && (
          <POK src="/pok.svg" alt="Prophecy of Kings Objective" />
        )}
        <Div selected={selected}>
          {selected && (
            <Flex>
              <Heading>
                <H1>{selected}</H1>
                <H2 type={selectedAgenda.type}>{selectedAgenda.type}</H2>
              </Heading>
              <Text>
                {selectedAgenda.elect !== undefined &&
                  selectedAgenda.name !== 'Covert Legislation' && (
                    <P style={{ textAlign: 'center' }}>
                      <strong>Elect {selectedAgenda.elect}</strong>
                    </P>
                  )}
                {selectedAgenda.effect.text && (
                  <P>{selectedAgenda.effect.text}</P>
                )}
                {selectedAgenda.effect.for && (
                  <P>
                    <strong>FOR: </strong>
                    {selectedAgenda.effect.for}
                  </P>
                )}
                {selectedAgenda.effect.against && (
                  <P>
                    <strong>AGAINST: </strong>
                    {selectedAgenda.effect.against}
                  </P>
                )}
                {selectedAgenda.effect.elect && (
                  <P>{selectedAgenda.effect.elect}</P>
                )}
              </Text>
            </Flex>
          )}
          {selected ? null : <CardBack alt="" src={'/agenda.svg'} />}
        </Div>
      </SelectWrapper>
    </Block>
  );
};

export default AgendaCard;
