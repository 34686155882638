import { lighten, transparentize } from 'polished';
import styled from 'styled-components';
import { breakpoints, colors } from '../constants/variables';
import Button from './Button';

const Container = styled.div`
  position: relative;
`;

const MenuBlock = styled.div`
  position: absolute;
  ${(props) => `${props.posY}: 0;`}
  ${(props) => `${props.posX}: 0;`}
  transform: translateY(100%);
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  border: solid 1px ${lighten(0.3, colors.black)};
  z-index: 10;
  box-shadow: 0 0.5rem 2rem ${transparentize(0.8, colors.white)};
  background-color: ${colors.black};
  min-width: 8em;
  opacity: ${(props) => (props.show ? 1 : 0)};
  pointer-events: ${(props) => (props.show ? 'initial' : 'none')};
  display: ${(props) => (props.show ? 'flex' : 'none')};

  @media only screen and (max-width: ${breakpoints.xs}) {
    position: fixed;
    width: 75vw;
    padding: 1rem 0.5rem;
    display: flex;
    top: 0;
    left: 0;
    height: 100vh;
    box-shadow: none;
    border-left: none;
    border-right: none;
    border-bottom: none;
    transform: ${(props) =>
      props.show
        ? 'translateX(0%) translateY(0%)'
        : 'translateX(-100%) translateY(0%)'};
    border: none;
    transition: transform 0.3s ease-in-out,
      ${(props) => (props.show ? 'opacity linear' : 'opacity 0.3s linear 0.3s')};
  }
`;

const Heading = styled.div`
  display: none;

  @media only screen and (max-width: ${breakpoints.xs}) {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
`;

const H1 = styled.h1`
  margin: 0;
`;

const Dropdown = ({
  children,
  aria = 'Toggle Dropdown Menu',
  label,
  openIcon,
  closeIcon,
  show,
  toggle,
  innerRef,
  size = 'xs',
  posX = 'right',
  posY = 'bottom',
}) => {
  return (
    <Container ref={innerRef}>
      <Button
        outline
        size={size}
        square={!label}
        onClick={toggle}
        aria-label={!label ? aria : null}
      >
        {label && label}
        {!label && show && closeIcon}
        {!label && !show && openIcon}
      </Button>
      <MenuBlock
        posX={posX}
        posY={posY}
        show={show}
        disabled={!show}
        aria-hidden={!show}
      >
        <Heading>
          <H1>{label}</H1>
          <Button outline size="xs" square onClick={toggle}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill={colors.actionColor}
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
            </svg>
          </Button>
        </Heading>
        {children}
      </MenuBlock>
    </Container>
  );
};

export default Dropdown;
